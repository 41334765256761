import { mapGetters } from 'vuex';

export default {
	data () {
		return {
			selectedNewsSources: [],
			newNewsSource: {
				title: ''
			},
			errorMessages: []
		}
	},
	computed: {
		...mapGetters({
			newsSources: ['newsSource/newsSources'],
			isLoadingResources: 'isLoadingResources'
		})
	},
	created () {
		this.$store.dispatch('newsSource/fetchNewsSources').then(() => {
			this.isLoading = false;
		});
	},
	methods: {
		deleteNewsSources () {
			if(this.selectedNewsSources.length > 0) {
				this.$store.dispatch('newsSource/deleteNewsSources', this.selectedNewsSources).then(() => {
					this.$notify({
						title: this.selectedNewsSources.length > 1 ? 'Nieuwsbronnen zijn verwijderd' : 'Nieuwsbron is verwijderd',
						message: this.selectedNewsSources.length > 1 ? 'Nieuwsbronnen zijn succesvol verwijderd!' : 'Nieuwsbron is succesvol verwijderd!',
						type: 'success',
						position: 'bottom-right'
					});
					this.selectedNewsSources = []
				})
			}
		},
		createNewsSource () {
			this.$store.dispatch('newsSource/createNewsSource', this.newNewsSource).then(() => {
				this.errorMessages = []
				this.newNewsSource.title = '';
				this.$notify({
					title: 'Nieuwsbron toegevoegd',
					message: 'Nieuwsbron is succesvol aangemaakt!',
					type: 'success',
					position: 'bottom-right'
				})
			}).catch((errors) => {
				this.errorMessages = errors;
			});
		},
		toggleSelectedNewsSource (selectedId) {
			if(this.selectedNewsSources.includes(selectedId)) {
				this.selectedNewsSources = this.selectedNewsSources.filter(id => id !== selectedId)
			} else {
				this.selectedNewsSources.push(selectedId)
			}
		}
	}
}